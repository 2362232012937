// fetchBlogs.js
import axios from 'axios';

const fetchBlogs = async () => {
  try {
    const response = await axios.get(`http://localhost:30000/blogs`);
    return response.data.blogs;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    return [{ id: 1, title: 'Greska' }]; // vraća praznu listu u slučaju greške
  }
};

export default fetchBlogs;
